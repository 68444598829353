<template>
<div class="dashboard-page">
  <!-- filter: period  -->
  <div class="row">
    <div class="col-sm-12">
      
      <vuestic-widget class="filter-widget">
        <template slot="header">
          <div class="w-100 d-flex">
            <span class="title flex-fill">Analytics</span>
            <button class="btn btn-primary btn-sm btn-print" @click="handlePrint">
              Print <i class="fa fa-print"></i>
            </button>
          </div>
        </template>
        <div class="row">
          <div class="col-md-4">
            <label class="field-label">Date</label>
            <date-range-picker
              ref="picker"
              :opens="filter.date.opens"
              :locale-data="{ firstDay: 1, format: 'MMMM D, YYYY' }"
              :min-date="filter.date.minDate" :maxDate="filter.date.maxDate"
              :single-date-picker="filter.date.singleDatePicker"
              :time-picker="filter.date.timePicker"
              :time-picker24-hour="filter.date.timePicker24Hour"
              :show-week-numbers="filter.date.showWeekNumbers"
              :show-dropdowns="filter.date.showDropdowns"
              :auto-apply="filter.date.autoApply"
              :always-show-calendars="filter.date.alwaysShowCalendars"
              :linked-calendars="filter.date.linkedCalendars"
              :ranges="filter.date.ranges"
              v-model="filter.date.dateRange"
              @update="filterDateUpdateValues"
            >
            </date-range-picker>
          </div>
          <div class="col-md-4">
            <label class="field-label">Installs</label>
            <multiselect
              v-model="filter.install.value"
              :options="filter.install.options"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder=""
              label="label"
              track-by="label"
              :preselect-first="true"
              :show-labels="false"
              :allowEmpty="false"
              @input="filterDateUpdateValues"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <label class="field-label">Agents</label>
            <multiselect
              v-model="filter.agents.value"
              :options="filter.agents.options"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="All Agents"
              label="label"
              track-by="label"
              :preselect-first="false"
              :show-labels="false"
              :searchable="false"
              :allowEmpty="true"
              @input="filterDateUpdateValues"
            ></multiselect>
          </div>
        </div>
      </vuestic-widget>

      <div class="d-print col-12">
        <h3 class="text-center">{{ activeTabIndex === 0 ? 'Call' : 'Message' }} Stats</h3>
        Date Range: From <b>{{ filter.date.dateRange.startDate | date }}</b> To <b>{{ filter.date.dateRange.endDate | date }}</b> <br>
      </div>
    </div>
  </div>

  <div class="row">
    <div v-if="showMessageTabOnly" class="col-sm-12" >
      <MessageTab ref="messageRef" :filter="filter"/>
    </div>
    <div v-else-if="showCallTabOnly" class="col-sm-12" >
      <CallTab ref="callRef" :filter="filter"/>
    </div>
    <div v-else class="col-sm-12">
      <b-tabs v-model="activeTabIndex" card>
        <b-tab title="Calls" active>
          <CallTab ref="callRef" v-if="activeTabIndex === 0" :filter="filter"/>
        </b-tab>
        <b-tab title="Messages">
          <MessageTab ref="messageRef" v-if="activeTabIndex === 1" :filter="filter"/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</div>

</template>

<script>
import Moment from 'moment';
import CallTab from './Call';
import MessageTab from './Message';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  components: {
    DateRangePicker,
    CallTab,
    MessageTab,
  },
  data: function () {
    return {
      activeTabIndex: 0,
      filter : {
        agents: {
          value: [],
          options: [
          ]
        },
        install : {
          value: {id: 'all', label: 'All Installs'},
          options: [
            {id: 'all', label: 'All Installs'},
          ]
        },
        date : {
          opens: 'right',
          minDate: null,
          maxDate: null,
          singleDatePicker: false,
          timePicker: false,
          timePicker24Hour: false,
          showWeekNumbers: false,
          showDropdowns: false,
          autoApply: false,
          dateRange: {
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
          },
          linkedCalendars: true,
          ranges: {
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(7, 'days'), moment()],
            'Last 30 Days': [moment().subtract(30, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          },
          alwaysShowCalendars: false,
        }
      },
    }
  },
  mounted() {
    this.getInstalls()
    this.getAgents()
  },
  watch: {
  },
  computed: {
    showMessageTabOnly() {
      return this.$store.getters['auth/widgetTypeTextOnly']
    },

    showCallTabOnly() {
      return this.$store.getters['auth/widgetTypePhoneOnly']
    }
  },
  methods: {
    getAgents() {
      this.$store.dispatch('businessUser/agents')
        .then((data ) => {
          this.filter.agents.options = data.map(item => {
            return {
              id: item.id,
              label: item.name
            }
          })
        })
        .catch(() => {});
    },

    formatPhone(val) {
      if (!val)
        return '';
      if (!val.startsWith('+'))
        val = '+' + val;

      const phoneNumber = parsePhoneNumberFromString(val)
      return phoneNumber.formatNational()
    },

    getInstalls() {
      const param = {
        status: "active",
      };
      this.$store
        .dispatch("install/fetchAllInstalls", param)
        .then(({ data }) => {
          if (data && data.length)
          {
            this.filter.install.options = [
              { id: 'all', label: 'All Installs'},
              ...data.map(item => {
                return {
                  id: item.id,
                  number: item.available_number,
                  label: (item.available_number ? this.formatPhone(item.available_number) + ' : ' : '' ) + item.website,
                }
              })
            ]
          }
        })
        .catch(() => {});
    },

    filterDateUpdateValues(range) {
      if (this.showMessageTabOnly)
      {
        this.$refs.messageRef.handleFilterUpdate()
      } else if (this.showCallTabOnly)
      {
        this.$refs.callRef.handleFilterUpdate()
      } else {
        if (this.activeTabIndex === 0)
        {
          this.$refs.callRef.handleFilterUpdate()
        } else {
          this.$refs.messageRef.handleFilterUpdate()
        }
      }
      return true
    },

    async handlePrint() {
      if (this.activeTabIndex === 0) {
        await this.$apexcharts.exec('chart-calls', 'dataURI')
          .then(({ imgURI }) => {
            document.getElementById('chart-calls-img').src = imgURI
          })
      
        await this.$apexcharts.exec('chart-time-of-day', 'dataURI')
          .then(({ imgURI }) => {
            document.getElementById('chart-time-of-day-img').src = imgURI
          })
      } else {
        await this.$apexcharts.exec('chart-messages', 'dataURI')
          .then(({ imgURI }) => {
            document.getElementById('chart-messages-img').src = imgURI
          })
      
        await this.$apexcharts.exec('chart-messages-time-of-day', 'dataURI')
          .then(({ imgURI }) => {
            document.getElementById('chart-messages-time-of-day-img').src = imgURI
          })
      }

      window.print()
    }
  },
};
</script>

<style lang="scss" scoped>
  .filter-widget {
    margin-bottom: 0;
  }
</style>

<style lang="scss">
  .vue-daterange-picker {
    display: block !important;

    //make range picker behave as adequate input
    .reportrange-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0.45rem 0.75rem;
      border: .0625rem solid #e7e7e7;
      height: inherit;
    }

    .daterangepicker {
      transform: scale(1);
      opacity: 1;

      //Move ranges list to the right
      .ranges {
        order: 10;
      }
    }
  }

  .d-print {
      display: none;
  }

  @media print {
    .d-none-print {
      display: none;
    }
    canvas {
      min-height: 100%;
      max-width: 100%;
      max-height: 100%;
      height: auto!important;
      width: auto!important;
    }

    .chart-widget {
      width: 100%;
    }

    * {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important; 
      print-color-adjust: exact !important; 
    }
    .col-print, .col-print {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
    .widget-body {
      padding: 0px !important;
    }
    .widget-flex-item .widget-body {
      padding: 1.5625rem !important;
    }
    .tabs .card-header {
      display: none;
    }
    .filter-widget {
      display: none;
    }
    .d-print {
      display: block;
    }
    .vuestic-sidebar.sidebar {
      display: none;
    }

    .stn-wdgt {
      display: none;
    }

    .btn-print {
      display: none;
    }

    .list-call-stats {
      display: block !important;
    }
  }
</style>

